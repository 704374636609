html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  background-image: url("https://i.imgur.com/RkxgrtL.jpeg");
  backdrop-filter: blur(7px);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.89);
  z-index: -1;
}

/* Overlay effects for jackets on tier lists */
.notplayed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  background-blend-mode: screen, difference, normal;
  opacity: 0.75;
  transition: opacity 0.2s ease-in-out;
}
.notplayed:hover {
  opacity: 0.2;
}
.notpuc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  background-blend-mode: screen, difference, normal;
  opacity: 0.35;
  transition: opacity 0.15s ease-in-out;
}
.notpuc:hover {
  opacity: 0;
}
.puc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    /* conic-gradient(
      from 180deg at 50% 50%,
      rgba(0, 0, 0, 1) 0deg,
      rgba(255, 255, 255, 0.7) 17deg,
      rgba(0, 0, 0, 1) 88deg,
      rgba(255, 255, 255, 0.7) 152deg,
      rgba(0, 0, 0, 1) 225deg,
      rgba(255, 255, 255, 0.7) 289deg,
      rgba(0, 0, 0, 1) 360deg
    ),
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(0, 0, 0, 1) 0deg,
      rgba(255, 255, 255, 1) 30deg,
      rgba(0, 0, 0, 1) 96deg,
      rgba(255, 255, 255, 1) 169deg,
      rgba(0, 0, 0, 1) 229deg,
      rgba(255, 255, 255, 1) 285deg,
      rgba(0, 0, 0, 1) 360deg
    ), */
    radial-gradient(
      88% 127% at 13% 13%,
      rgba(248, 110, 251, 1) 8%,
      rgba(115, 66, 255, 1) 35%,
      rgba(66, 232, 255, 1) 63%,
      rgba(66, 255, 107, 1) 100%
    );
    background-blend-mode: screen, difference, normal;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
}
.puc:hover {
  opacity: 0.6;
}


/* Rainbow border around PUCs on tier lists */
.rainbow-border {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  padding: 9px !important;
  box-sizing: border-box;
  transition: padding 0.12s ease-in-out;
}
.rainbow-border:hover {
  padding: 11px !important;
}
.rainbow-border::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    #FF9AA2 0%, 
    #FFB7B2 10%, 
    #FFDAC1 20%, 
    #E2F0CB 30%, 
    #B5EAD7 40%, 
    #C7CEEA 50%, 
    #9AA2FF 60%, 
    #B7B2FF 70%, 
    #DAC1FF 80%, 
    #F0CBFF 90%, 
    #FF9AA2 100%
  );
  opacity: 0.9;
  /* animation: rotate 4s linear infinite; */
}

#back-to-top {
  position: sticky;
  bottom: 20px;
  left: 50%;
  opacity: 0;
  translate: -50%;
  transition: opacity 0.2s linear;
  z-index:1000;
}

#back-to-top.back-to-top-visible {
  opacity: 1;
}
/* @keyframes rotate {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
} */

/* Old background for pages, animated sweeping gradient */
/* gradient bg
body::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-45deg, 
  rgba(238, 119, 82, 0.2),
  rgba(231, 60, 126, 0.2), 
  rgba(35, 166, 213, 0.2), 
  rgba(35, 213, 171, 0.2));
  background-size: 400% 400%;
  animation: bg-gradient 30s ease infinite;
  z-index: -1;

}
@keyframes bg-gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
} */